import React, { useEffect } from 'react';
import {
  Grid,
  makeStyles,
  Paper,
  RadioGroup,
  Radio,
  Typography,
  TextField,
} from '@material-ui/core';

import colors from 'utils/colors';
import { Description } from '@material-ui/icons';
import CustomAutoComplete from 'commons/components/CustomAutoComplete';

const { primary, grey } = colors;

const styles = (theme) => ({
  radioGroup: { display: 'flex', flexDirection: 'column', alignItems: 'center' },
  gridChildContainer: {
    display: 'flex',
    justifyContent: 'center',
    '& .selected': {
      boxShadow: '0px 0px 6px 5px rgba(69,198,216,0.50)',
      WebkitBoxShadow: ' 0px 0px 6px 0px 5px rgba(69,198,216,0.50)',
      MozBoxShadow: '0px 0px 6px 0px 5px rgba(69,198,216,0.50)',
      order: '1px solid rgba(0,0,0,0.1)',
    },
    '& .paperContainer': {
      width: '100%',
      padding: '.5rem',
      display: 'flex',
      flexDirection: 'column',
      cursor: 'pointer',
      '& .radio-button': {
        '&.Mui-checked': {
          color: primary,
        },
      },
      '& .checked': {
        '& .title': {
          color: primary,
        },
        '& .icon': {
          color: primary,
        },
      },

      '& .topSubcontainer': {
        display: 'flex',
        alignItems: 'center',
        '& .radioContainer': {
          display: 'flex',
          alignItems: 'flex-start',
          justifyContent: 'center',
          padding: '0px',
          '& span': {
            padding: '0px',
          },
        },
        '& .checked': {
          '& .title': {
            color: primary,
          },
        },
      },
      '& .lowerSubcontainer': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-end',
        marginTop: '.5rem',
        '& img': {
          width: '120px',
        },
        '& .disabled': {
          filter: 'grayscale(1)',
        },
      },
    },
    '& .disabled': {
      cursor: 'not-allowed',
      '& .title': {
        color: grey,
      },
      '& .icon': {
        color: grey,
      },
    },
  },
});

const useStyles = makeStyles(styles);

const DeliveryMethodsBox = ({
  actions,
  handleChange,
  handleClick,
  selectedDeliveryMethod,
  onControlChange,
  controls={},
  communes,
  buyerInfo,
  sellerInfo,
}) => {
  const classes = useStyles();
  const deliveryMethods = [
    {
      id: 1,
      key: 'OnStore',
      title: 'Retiro en tienda',
      subtitle: 'Gratis',
      description: `(${sellerInfo?.addressStreet} ${sellerInfo?.addressNumber} ${sellerInfo?.addressDetail || ''})`,
      disabled: false,
    },
    {
      id: 2,
      key: 'SendToAddress',
      title: 'Despacho a domicilio',
      subtitle: 'Valor estimado $9.900',
      description: '(3-5 días RM)',
      disabled: false,
    },
  ];
  const { gridChildContainer, radioGroup } = classes;

  return (
    <Grid container justifyContent="center" spacing={6} alignItems='center' alignContent='center' direction="row">
      <Grid item xs={12} sm={5}>
        <RadioGroup value={selectedDeliveryMethod} onChange={handleChange} className={radioGroup}>
          <Grid container justifyContent="center" spacing={2} direction="column">
            {deliveryMethods?.map((deliveryMethod) => (
              <Grid key={deliveryMethod.id} item xs={12} className={gridChildContainer}>
                <Paper
                  className={deliveryMethod.disabled ? 'paperContainer disabled' : 'paperContainer'}
                  className={
                    selectedDeliveryMethod === deliveryMethod.key
                      ? 'paperContainer selected'
                      : 'paperContainer'
                  }
                  onClick={deliveryMethod.disabled ? null : () => handleClick(deliveryMethod.key)}
                >
                  <Grid className="topSubcontainer" container item xs={12}>
                    <Grid className="radioContainer" item xs={2}>
                      <Radio
                        value={deliveryMethod.key}
                        className="radio-button"
                        disabled={deliveryMethod.disabled}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={10}
                      className={selectedDeliveryMethod === deliveryMethod.key && 'checked'}
                    >
                      <Typography className="title">{deliveryMethod.title}</Typography>
                    </Grid>
                    <Grid item xs={2} style={{ height: '1rem' }} />
                    <Grid
                      item
                      xs={10}
                      className={selectedDeliveryMethod === deliveryMethod.key && 'checked'}
                    >
                      <Typography color="textSecondary">{deliveryMethod.subtitle}</Typography>
                      <Typography color="textSecondary">{deliveryMethod.description} </Typography>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            ))}
          </Grid>
        </RadioGroup>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Grid container justifyContent="space-between" spacing={1} direction="column">
          <Grid item className={gridChildContainer}>
            <Typography variant="h6" component="span" color="textSecondary">
              Dirección de Despacho
            </Typography>
          </Grid>
          <Grid item /* className={gridChildContainer} */ xs={12} sm={12}>
            <CustomAutoComplete
              // size="large"
              id="commune"
              name="commune"
              label="Comuna"
              value={controls.commune}
              onChange={onControlChange} // onChange
              options={communes}
              // error={!!errors.comuneId}
              // errors={errors.comuneId}
            />
          </Grid>
          <Grid item className={gridChildContainer}>
            <TextField
              fullWidth
              id="addressStreet"
              name="addressStreet"
              label="Nombre de calle"
              variant="outlined"
              value={controls.addressStreet}
              onChange={onControlChange}
            // error={!!errors.addressStreet}
            // helperText={errors.addressStreet}
            />
          </Grid>
          <Grid item className={gridChildContainer}>
            <TextField
              fullWidth
              id="addressNumber"
              name="addressNumber"
              label="Número de calle"
              variant="outlined"
              value={controls.addressNumber}
              onChange={onControlChange}
            // error={!!errors.addressStreet}
            // helperText={errors.addressStreet}
            />
          </Grid>
          <Grid item className={gridChildContainer}>
            <TextField
              fullWidth
              id="apartment"
              name="apartment"
              label="Casa, Depto u Oficina"
              variant="outlined"
              value={controls.apartment}
              onChange={onControlChange}
            // error={!!errors.addressStreet}
            // helperText={errors.addressStreet}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DeliveryMethodsBox;
