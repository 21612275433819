import React, { useEffect, useState } from 'react';

// MATERIAL
import {
  Box,
  Button,
  Card,
  Typography,
  CardContent,
  Grid,
  withStyles,
  TextField,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import CustomAutoComplete from 'commons/components/CustomAutoComplete';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
// COMPONENTS
import { composedComponent } from 'utils/functions';
import Categories from 'utils/categories';
import { appActions } from 'commons/reducer';


import saga from '../../saga';
import { styles } from './styles';
import BikeImage from 'screens/Home/components/BikeImage';
import CustomInputNumber from 'commons/components/CustomInputNumber';
// import { homeActions } from '../../reducer';

// DatePublished se ha ocultado a pedido del cliente, se debe quitar comentario en caso se requiera.
const validate = (value) => value || 'No especificado';

const BikeCard = (props) => {
  const {
    isReportedBike,
    setOpenPhotosModal,
    transfer,
    classes,
    bikeData,
    sendSimulationEmail,
    history,
    user
  } = props;
  const { brandName, modelName, bikeYear, sizeName, rimName } = bikeData;
  const theme = useTheme();
  const isXS = useMediaQuery(theme.breakpoints.down('xs'));

  const [amountExpected, setAmountExpected] = useState('');
  const [status, setStatus] = useState('');

  const bikeCardDescription = `Talla ${validate(sizeName)} | Año ${validate(bikeYear)}
    ${rimName ? `| Aro ${rimName}` : ''}`;

  const imgUrl = transfer?.bike?.documents?.find((d) => d.type === 'right_side')?.file.url;
  console.log('imgurlfeewfew__:: ', imgUrl);

  const handleChangeAmountExpected = (e) => {
    setAmountExpected(e.target.value);
  };

  const handleChangeStatus = (e) => {
    setStatus(e.target.value);
  };

  const handleSubmitSendSimulation = () => {
    sendSimulationEmail({
      user,
      bikeData: {
        bikeId: transfer?.bikeId,
        amountExpected,
        status,
      },
      history,
    });
  };

  return (
    <Card
      className={`${classes.root}`}
      style={{ cursor: 'pointer', marginBottom: '1rem' }}
      sm="12"
    >
      <Grid container>
        <Grid item sm={5} xs={12}>
          <BikeImage
            url={imgUrl}
            reported={isReportedBike}
            openModal={setOpenPhotosModal}
            postedByBikfyStore={transfer?.postedByBikfyStore}
          />
        </Grid>
        <Grid item sm={7} xs={12} className={classes.details}>
          <CardContent className={classes.content}>
            <Grid item className="bike-info-container">
              <Typography
                itemProp="name"
                variant="h2"
                style={{
                  fontWeight: '500',
                  fontSize: '1.25rem',
                  fontFamily: 'Mark Pro',
                  lineHeight: 1.6,
                }}>
                <Box className={`${classes.bikfyGreyColor} ${classes.normalListTitleCard}`}>
                  {`${brandName} ${modelName}`}
                </Box>
              </Typography>
            </Grid>
            <Box className="bike-info-container">
              <Typography
                display="inline"
                className={`${classes.bikfyGreyColor} ${classes.sizeYearTypography}`}
                style={isXS ? { margin: '5px 0px' } : {}}>
                {
                  bikeCardDescription
                }
              </Typography>
            </Box>
            <Box className="bike-info-container">
              {/* set a combo box with three status "bien", "muy bien", "mal" */}
              <Grid container direction='row' spacing={1}>
                <Grid item md="6">
                  <CustomInputNumber
                    label="¿Cuánto esperas recibir  por tu bici?"
                    id="amountExpected"
                    name="amountExpected"
                    variant="outlined"
                    onChange={handleChangeAmountExpected}
                    value={amountExpected}
                  />
                </Grid>
                <Grid item md="6">
                  <CustomAutoComplete
                    name="status"
                    label="Estado"
                    options={[{ value: 'como_nuevo', label: 'Como nuevo' }, { value: 'buen_estado', label: 'Buen estado' }, { value: 'aceptable', label: 'Aceptable' }]}
                    value={status}
                    onChange={handleChangeStatus}
                  />
                </Grid>
              </Grid>
            </Box>
          </CardContent>
          <Grid
            container
            spacing={1}
            justifyContent={isXS ? 'center' : 'space-between'}
            className={classes.controls}
            alignItems="flex-start">
            <Grid item style={{ flex: 1 }}>
              <Grid container spacing={2}>
                <Grid item>
                  <Button
                    variant="outlined"
                    color="primary"
                    startIcon={<MonetizationOnIcon />}
                    onClick={handleSubmitSendSimulation}
                    id="btn-send-whatsapp"
                    disabled={!amountExpected || !status || !imgUrl}
                  >
                    SOLICITAR SIMULACIÓN
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
};

export default composedComponent(BikeCard, saga, {
  saga: 'sagaHome',
  middlewares: [withStyles(styles)],
  states: [
    'app.objects',
    'app.objects.transfers',
    'app.order',
    'app.user',
    'home',
    'home.pathType',
    'home.sortBy',
    'home.storeHomeSortBy',
  ],
  actions: [appActions],
});
