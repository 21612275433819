import { put, spawn, takeLatest } from 'redux-saga/effects';
import { apiSuccess, get, post } from 'utils/api';
import * as APP from 'commons/reducer';
import { REDEEM_COUPON } from 'screens/RedeemCoupon/reducer';

// Fetch coupons for a user by taxNumber or all coupons if taxNumber is not provided
function* fetchCoupons() {
  yield takeLatest(REDEEM_COUPON.FETCH_COUPONS, function* (action) {
    yield put(apiSuccess(APP.ADD_LOADING, REDEEM_COUPON.FETCH_COUPONS));
    console.log('hereeee');

    const response = yield get(`api/v1/coupons${action.payload.taxNumber ? `?taxNumber=${action.payload.taxNumber}` : ''}`);
    console.log(response);
    yield put(apiSuccess(APP.REMOVE_LOADING, REDEEM_COUPON.FETCH_COUPONS));

    if (!response.error) {
      yield put(apiSuccess(APP.GET_STATE_FROM_API_SUCCESS, response));
      yield put(apiSuccess(REDEEM_COUPON.FETCH_COUPONS_SUCCESS, response.coupons));
    } else {
      yield put(apiSuccess(APP.SET_ERROR, { errorMsg: 'Error fetching coupons' }));
      yield put(apiSuccess(REDEEM_COUPON.FETCH_COUPONS_ERROR, { errorMsg: 'Error fetching coupons' }));
    }
  });
}

// Redeem a specific coupon by its code and the store ID (usedIn)
function* redeemCoupon() {
  yield takeLatest(REDEEM_COUPON.REDEEM_COUPON, function* (action) {
    yield put(apiSuccess(APP.ADD_LOADING, REDEEM_COUPON.REDEEM_COUPON));

    const response = yield post('api/v1/coupons/redeem', {
      code: action.payload.code, usedIn: action.payload.usedIn, store: action.payload.store, buyer: action.payload.buyer,
    });
    console.log(response);
    yield put(apiSuccess(APP.REMOVE_LOADING, REDEEM_COUPON.REDEEM_COUPON));

    if (!response.error) {
      // yield put(apiSuccess(APP.GET_STATE_FROM_API_SUCCESS, response));
      yield put(apiSuccess(APP.SET_SUCCESS, 'Cupón cargado correctamente'));
      yield put(apiSuccess(REDEEM_COUPON.REDEEM_COUPON_SUCCESS, response));
    } else {
      yield put(apiSuccess(APP.SET_ERROR, { errorMsg: 'Error redeeming coupon' }));
      yield put(apiSuccess(REDEEM_COUPON.REDEEM_COUPON_ERROR, { errorMsg: 'Error redeeming coupon' }));
    }
  });
}

// Consult a coupon code and send it to the provided email
function* consultCouponCode() {
  yield takeLatest(REDEEM_COUPON.CONSULT_COUPON_CODE, function* (action) {
    yield put(apiSuccess(APP.ADD_LOADING, REDEEM_COUPON.CONSULT_COUPON_CODE));

    const response = yield post('api/v1/coupons/consult', {
      couponId: action.payload.couponId,
      email: action.payload.email,
    });
    console.log(response);
    yield put(apiSuccess(APP.REMOVE_LOADING, REDEEM_COUPON.CONSULT_COUPON_CODE));

    if (!response.error) {
      yield put(apiSuccess(APP.GET_STATE_FROM_API_SUCCESS, response));
      yield put(apiSuccess(REDEEM_COUPON.CONSULT_COUPON_CODE_SUCCESS, response));
    } else {
      yield put(apiSuccess(APP.SET_ERROR, { errorMsg: 'Error consulting coupon code' }));
      yield put(apiSuccess(REDEEM_COUPON.CONSULT_COUPON_CODE_ERROR, { errorMsg: 'Error consulting coupon code' }));
    }
  });
}

export default function* saga() {
  yield spawn(fetchCoupons);
  yield spawn(redeemCoupon);
  yield spawn(consultCouponCode);
}
