import React from 'react';
import { Box, Button, makeStyles, Typography } from '@material-ui/core';
import logoReport from 'commons/assets/bike-logo-report.svg';
import VisibilityIcon from '@material-ui/icons/Visibility';
import BlockIcon from '@material-ui/icons/Block';
import BeenhereIcon from '@material-ui/icons/Beenhere';
import styles from './styles';

const useStyles = makeStyles(styles);

const BikeImage = (props) => {
  const { reported, openModal, url, style, isDisabled, postedByBikfyStore } = props;

  const classes = useStyles();

  const isReportedOrDisabled = reported || isDisabled;

  const handleModal = (e) => {
    e.preventDefault();
    e.stopPropagation();
    openModal();
  };

  if (url) {
    return (
      <Box style={{ ...style }} display="flex" className={classes.root} onClick={handleModal}>
        <img
          src={url}
          alt="Foto"
          className={`${classes.img} ${isReportedOrDisabled ? classes.blur : ''}`}
          loading="lazy"
        />
        {postedByBikfyStore && (
          <Box
            style={{
              position: 'absolute',
              top: 25,
              left: 0,
              zIndex: 1,
            }}>
            <BeenhereIcon
              className={classes.icon}
              color="primary"
              style={{
                fontSize: 45,
                color: '#FFD700',
              }}
            />
          </Box>
        )}

        {isReportedOrDisabled ? (
          <Box className={classes.rootReport}>
            <img src={logoReport} style={{ height: '70%' }} />
            <Typography variant="h6" className={`${classes.reportText} color-danger`}>
              <Box fontWeight="fontWeightBold">BIKE {reported ? 'DENUNCIADA' : 'INHABILITADA'}</Box>
            </Typography>
          </Box>
        ) : (
          <>
            <Box className={classes.eye}>
              <VisibilityIcon className={classes.icon} color="primary" style={{ fontSize: 60 }} />
              <Button variant="outlined" size="small" color="primary" className={classes.btn}>
                ver fotos
              </Button>
            </Box>
          </>
        )}
      </Box>
    );
  }
  return (
    <Box display="flex" className={classes.root}>
      <BlockIcon className={classes.icon} style={{ fontSize: 200 }} />
    </Box>
  );
};

export default BikeImage;
