import React from 'react';
import Grid from '@material-ui/core/Grid';
import {
  Typography,
  Box,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Link } from 'react-router-dom';
import { PATHS } from 'utils/paths';

const BuyYourBikeForm = ({
  profileComplete,
  className,
}) => (
  <Grid container justifyContent="center">
    <Grid container xs={12} sm={12} justifyContent="center">
      <Typography className={className?.primaryTitle} align="center" component="h1">
        Compramos tu
        <Typography color="primary" variant='span' className={className?.secondaryTitle} align="center">
          {' '}Bicicleta
        </Typography>
      </Typography>
    </Grid>
    <Grid container xs={12} sm={12} justifyContent="center">
      <Typography className={className?.informativeText} align="center">
        Véndenos tu bicicleta en modalidad parte de pago y renuévala por una Semi-nueva o una nueva de nuestras marcas adheridas. Solicita una simulación asegurándote de tener fotos actualizadas de tu bicicleta.
      </Typography>
    </Grid>
    {!profileComplete && (
      <Grid item xs={10} sm={10}>
        <Box>
          <Alert severity="info">
            <Typography align="center" className="message">
              Debes <Link to={`/login?href=${PATHS.OFFER_FOR_BIKE}`}>iniciar sesión</Link> para
              poder ofertar por una Bike
            </Typography>
          </Alert>
        </Box>
      </Grid>
    )}
  </Grid>
);

export default BuyYourBikeForm;
