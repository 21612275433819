import React from 'react';
import PropTypes from 'prop-types';

// MATERIAL
import { Typography, Box } from '@material-ui/core';

import { formatNumberToCLP } from '../../../utils/functions';
import useTotalAmountStyles from './useTotalAmountStyles';

const TotalAmountBox = (props) => {
  const {
    className, label, totalAmount, styles, isPurchase = false,
  } = props;

  const classes = useTotalAmountStyles();

  const coupon = 100000;

  return (
    isPurchase
      ? (
        <Box style={{ ...styles }} className={`${classes.amountBoxContainer} ${className}`}>
          <Box className="amountTitleContainer">
            <Typography className="amountTitle">{label}</Typography>
          </Box>
          <Box className="amountBox" style={{ textAlign: 'left' }}>
            <div style={{ textAlign: 'left' }}>
              <Typography style={{ color: 'black', marginRight: 0 }}>Precio: {formatNumberToCLP(totalAmount)}</Typography>
              <Typography style={{ color: 'black', marginRight: 0 }}>Valor cupón: {formatNumberToCLP(coupon)}</Typography>
            </div>
            <Typography className="amount">Monto a pagar: {formatNumberToCLP(totalAmount - coupon)}</Typography>
          </Box>
        </Box>
      )
      : (
        <Box style={{ ...styles }} className={`${classes.amountBoxContainer} ${className}`}>
          <Box className="amountTitleContainer">
            <Typography className="amountTitle">{label}</Typography>
          </Box>
          <Box className="amountBox">
            <Typography className="amount">{formatNumberToCLP(totalAmount)}</Typography>
          </Box>
        </Box>
      )
  );
};

TotalAmountBox.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  totalAmount: PropTypes.number,
  enableBikfyPay: PropTypes.bool,
  styles: PropTypes.object,
};

TotalAmountBox.defaultProps = {
  className: '',
  label: 'Monto que recibirás',
  totalAmount: 0,
  enableBikfyPay: false,
  styles: {},
};

export default TotalAmountBox;
