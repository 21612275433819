import React, { useEffect, useState, useCallback } from 'react';
import { useParams, useHistory } from 'react-router-dom';

import {
  Grid,
  Step,
  Stepper,
  StepLabel,
  makeStyles,
  Card,
  CardContent,
  Typography,
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { composedComponent } from 'utils/functions';
import { appActions } from 'commons/reducer';
import { useAuth } from 'commons/context/user';
import {
  FIRST_STAGE_PURCHASE_ID,
  SECOND_STAGE_PURCHASE_ID,
  THIRD_STAGE_PURCHASE_ID,
  FOURTH_STAGE_PURCHASE_ID,
} from 'utils/consts';
import { useForm } from 'utils/hooks/useForm';
import saga from 'screens/PurchasePreview/saga';
import styles from './styles.js';
import { purchasePreviewActions } from './reducer/index.js';
import PurchaseButton from './components/PurchaseButton/index.js';
import Contract from 'screens/TransferPreview/components/Contract/index.js';
import BikfyPay from 'screens/TransferPreview/components/BikfyPay/BikfyPay.js';
import SuccessPay from 'screens/TransferPreview/components/SuccessPay/SuccessPay.js';
import TransferSummary from 'screens/Transfer/components/transferPreview/components/transferSummary';
import DeliveryMethodsBox from './components/DeliveryMethodsBox/index.js';


const useStyles = makeStyles(styles);

const CREDIT_CARD_PAYMENT = 'credit_card';
const CREDIT_CARD_PAYMENT_WITH_FEES = 'credit_card_with_fees';
const EXTERNAL_PAYMENT = 'external_payment';

const PurchasePreview = (props) => {
  const { app, objects, actions, formControls={}, purchasePreview } = props;
  // const { paymentMethod } = purchasePreview;
  const {
    getTransfer,
    deleteTransfer,
    createTransferPDF,
    setPaymentMethod,
    createPayment,
    createMercadoPagoPayment,
    confirmExternalPaymentToStore,
    nextStep
  } = actions;
  const { id: transferId } = useParams();
  useEffect(() => {
    getTransfer({ transferId });
  }, []);
  // const transfer = objects?.transfers?.[transferId];
  const user = useAuth();
  const classes = useStyles();
  const history = useHistory();
  const { stepper } = classes;
  const transfer = objects?.transfers?.[transferId];
  const communes = Object.values(props?.comunes).map(c => ({ value: c?.id, label: c?.name }));
  const sellerInfo = transfer?.sellerObject;
  // console.log('objectsOriginal OFFF:: ', Object.values(objects?.transfers)[0]?.buyerId);
  console.log('transfer OFFF:: ', transfer);
  // console.log('OBJECTS OFFF:: ', objects);
  console.log('sellerInfo OFFF:: ', sellerInfo);

  const userType =
    user.id === Object.values(objects?.transfers)[0]?.buyerId // || user.id === transfer?.buyerId
      ? 'buyer'
      : user.id === Object.values(objects?.transfers)[0]?.sellerId // || user.id === transfer?.sellerId
        ? 'seller'
        : null;

  const buyerObject = Object.values(objects?.buyer)[0];
  const buyerInfo = {
    commune: buyerObject?.comuneId,
    addressStreet: buyerObject?.addressStreet,
    addressNumber: buyerObject?.addressNumber,
    apartment: buyerObject?.addressDetail,
  };

  const bikeDetails = transfer?.bike;
  const stepZ = transfer?.transferDetail?.step;
  const commission = Object.values(objects?.commissions)[0];

  const [selectedDeliveryMethod, setSelectedPaymentMethod] = useState('OnStore');
  const deliveryMethodSelect = (deliveryMethodKey) => {
    // Lógica para manejar el clic en el método de entrega
    console.log(`Se ha seleccionado el método de entrega: ${deliveryMethodKey}`);
    setSelectedPaymentMethod(deliveryMethodKey);
  };

  // const step = 2;
  const [step, setStepEo] = useState(stepZ);
  const increaseStep = () => { setStepEo(step + 1); };
  const decreaseStep = () => { setStepEo(step - 1); };

  useEffect(() => {
    setStepEo(transfer?.transferDetail?.step);
    // setStepEo(4);
  }, [transfer?.transferDetail?.step]);

  const changeControls = useCallback((name, value) => {
    // if (name === 'email') {
    //   value = cleanWhiteSpaces(value);
    // }
    actions.changeControls({ [name]: value });
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    // onSubmit(() => actions.transferValidation({ id, ...formControls }));
  };

  const handleClickContinueButton = () => {
    increaseStep();
    nextStep({ transferId, formControls });
  };

  useEffect(() => {
    if (purchasePreview.transbank.url !== '' && purchasePreview.transbank.token !== '') {
      const urlOptions = {
        [EXTERNAL_PAYMENT]: purchasePreview.transbank.url.concat(`?token_ws=${purchasePreview.transbank.token}`),
        [CREDIT_CARD_PAYMENT]: purchasePreview.transbank.url.concat(`?token_ws=${purchasePreview.transbank.token}`),
        [CREDIT_CARD_PAYMENT_WITH_FEES]: purchasePreview.transbank.url,
      };

      window.location.replace(urlOptions[purchasePreview.paymentMethod]);
    }
  }, [purchasePreview.transbank]);

  useEffect(() => {
    if (selectedDeliveryMethod === 'OnStore') {
      actions.changeControls({ commune: sellerInfo?.comuneId || '' });
      actions.changeControls({ addressStreet: sellerInfo?.addressStreet || '' });
      actions.changeControls({ addressNumber: sellerInfo?.addressNumber || '' });
      actions.changeControls({ apartment: sellerInfo?.addressDetail || '' });
    } else {
      actions.changeControls({ commune: buyerInfo?.commune || '' });
      actions.changeControls({ addressStreet: buyerInfo?.addressStreet || '' });
      actions.changeControls({ addressNumber: buyerInfo?.addressNumber || '' });
      actions.changeControls({ apartment: buyerInfo?.apartment || '' });
    }
  }, [objects?.buyer, selectedDeliveryMethod]);

  const { onChange, onSubmit, errors } = useForm(formControls, null, {
    onChange: changeControls,
    validations: {
      commune: [
        {
          check: (value) => !!value,
          message: 'La comuna es requerida',
        },
      ],
      addressStreet: [
        {
          check: (value) => value && value.trim().length > 0,
          message: 'El nombre de calle es requerido',
        },
      ],
      addressNumber: [
        {
          check: (value) => value && value.trim().length > 0,
          message: 'El número de calle es requerido',
        },
      ],
      // apartment: [
      //   {
      //     check: (value) => value && value.trim().length > 0,
      //     message: 'El Precio de venta es requerido',
      //   },
      // ],
    },
  });

  useEffect(() => {
    const eo = props.actions.getStateFromApiComunes();
    // console.log('EOzzz:: ', eo);
  }, []);


  const contractScreen = (
    <Grid container justifyContent="center">
      <Grid item xs={10} sm={10}>
        <Card>
          <CardContent>
            <Grid container spacing={3}>
              <Grid container item xs={12} justifyContent="center" style={{ marginTop: '2rem' }}>
                <Typography
                  variant="h6"
                  component="span"
                  color="textSecondary"
                  style={{ fontWeight: '700', textTransform: 'uppercase' }}>
                  Revisa el
                  <Typography
                    variant="h6"
                    component="span"
                    color="primary"
                    style={{ fontWeight: '700', textTransform: 'uppercase' }}>
                    {' '}
                    Contrato de Compraventa
                    {' '}
                  </Typography>
                  para una transferencia exitosa
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} align="center">
                <Card variant="outlined" style={{ border: '1px solid black' }}>
                  <CardContent>{<Contract id={transferId} />}</CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} sm={12}>
                <TransferSummary
                  // handleOpenModal={handleOpenModal}
                  transfer={transfer}
                  amount={transfer?.amount}
                  buyer={transfer?.buyer}
                  seller={transfer?.seller}
                  bike={transfer?.bike}
                  storeSeller={transfer?.storeSeller}
                  purchase="purchase"
                />
              </Grid>
              <Grid item xs={12} sm={12} align="center">
                <PurchaseButton
                  // id={id}
                  id={transferId}
                  // user={userType}
                  acceptSeller={props.actions.acceptTransferSeller}
                  acceptBuyer={props.actions.acceptTransferBuyer}
                  loading={props.loading}
                  alertDeny={props.alertDeny}
                  openAlertDeny={props.actions.openAlert}
                  clearAlertDeny={props.actions.clearAlert}
                  deleteTransfer={props.actions.deleteTransfer}
                  createTransferPDF={createTransferPDF}
                  increaseStep={increaseStep}
                  // decreaseStep={decreaseStep}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );

  const deliveryScreen = (
    <Grid container justifyContent="center">
      <Grid item xs={10} sm={10}>
        <Card>
          <CardContent>
            <Grid container spacing={3}>
              <Grid container item xs={12} justifyContent="center" style={{ marginTop: '2rem' }}>
                <Typography
                  variant="h6"
                  component="span"
                  color="textSecondary"
                  style={{ fontWeight: '700', textTransform: 'uppercase' }}>
                  ¿Cómo deseas
                  <Typography
                    variant="h6"
                    component="span"
                    color="primary"
                    style={{ fontWeight: '700', textTransform: 'uppercase' }}>
                    {' '}
                    recibir tu bike
                    {' '}
                  </Typography>
                  ?
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} align="center">
                <DeliveryMethodsBox
                  actions={actions}
                  onControlChange={onChange}
                  onSubmit={handleSubmit}
                  handleClick={deliveryMethodSelect}
                  selectedDeliveryMethod={selectedDeliveryMethod}
                  controls={formControls}
                  communes={communes}
                  buyerInfo={buyerInfo}
                  sellerInfo={sellerInfo}
                />
              </Grid>
              <Grid item xs={12} sm={12} align="center">
                <Grid container justifyContent="center">
                  <Grid item xs={6} sm={3} md={2}>
                    <Button
                      onClick={decreaseStep}
                      type="submit"
                      variant="contained"
                      color="secondary">
                      Cancelar
                    </Button>
                  </Grid>
                  <Grid item xs={6} sm={3} md={2}>
                    <Button
                      onClick={handleClickContinueButton}
                      type="submit"
                      variant="contained"
                      color="primary"
                    >
                      Continuar
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );

  const paymentScreen = (
    <BikfyPay
      buyer={transfer?.buyer}
      transfer={transfer}
      commission={commission}
      createPayment={createPayment}
      createMercadoPagoPayment={createMercadoPagoPayment}
      setPaymentMethod={setPaymentMethod}
      paymentMethod={purchasePreview.paymentMethod}
      // loading={loading}
      // history={history}
      bikeInfo={transfer?.bike}
      storeSeller={transfer?.storeSeller}
      confirmExternalPaymentToStore={confirmExternalPaymentToStore}
      onControlChange={onChange}
      controls={formControls}
      decreaseStep={decreaseStep}
      isPurchase="purchase"
    />
  );

  const successPay = (
    <SuccessPay
      bikeDetails={bikeDetails}
      history={history}
      isBikfyPay={!transfer?.transferDetail.externalPayment}
      isPurchase="purchase"
    />
  );

  return (
    <Grid style={{ width: '100%' }}>
      <Stepper
        activeStep={step === FOURTH_STAGE_PURCHASE_ID ? step + 1 : step - 1}
        className={stepper}>
        <Step>
          <StepLabel />
        </Step>
        <Step>
          <StepLabel />
        </Step>
        <Step>
          <StepLabel />
        </Step>
        <Step>
          <StepLabel />
        </Step>
      </Stepper>


      <Grid>
        {step === FIRST_STAGE_PURCHASE_ID && (
          contractScreen
        )}
        {step === SECOND_STAGE_PURCHASE_ID && (
          deliveryScreen
        )}
        {step === THIRD_STAGE_PURCHASE_ID && (
          paymentScreen
        )}
        {step === FOURTH_STAGE_PURCHASE_ID && (
          successPay
        )}
      </Grid>
    </Grid>
  );
};

export default composedComponent(PurchasePreview, saga, {
  states: [
    'app.objects',
    'purchasePreview',
    'purchasePreview.formControls',
    // 'purchasePreview',
    'app.objects.comunes',
  ],
  actions: [appActions, purchasePreviewActions],
  saga: 'PurchasePreviewSaga',
});
